<template>
  <div class="leftpanel-container">
    <div id="pdfPaginationArrows" />
    <div class="pdf-wrapper">
      <CommonsPdfViewer
        v-show="!isFileErrored && isFileLoaded"
        :pdf-url="fileUrl"
        :mime-type="currentMimeType"
        :zoom="zoom"
        class="elevation-3-overwrite"
        :class="{ 'zoom-active': zoom > 1 }"
        @loaded="(isFileErrored = false), (isFileLoaded = true)"
        @error="(isFileErrored = true), (isFileLoaded = true)"
      />

      <CommonsPdfViewerZoom :zoom="zoom" @zoom-in="zoom = $event" @zoom-out="zoom = $event" />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { ReadAccrualInvoice } from "@silexpert/core";

const props = defineProps<{
  activeTransaction: ReadAccrualInvoice;
  activeTransactionIndex: number;
  transactions: ReadAccrualInvoice[];
}>();

const societyStore = useSocietyStore();

const fileUrl = ref("");
const isFileLoaded = ref(false);
const isFileErrored = ref(false);
const zoom = ref(1);

const idSociety = computed(() => societyStore?.society?.id ?? 0);

onMounted(async () => {
  if (props.activeTransaction.file) {
    const { id, name } = props.activeTransaction.file;
    try {
      const buffer = await $silex().file.getBuffer(idSociety.value, id!);
      const blobFile = new File([buffer], name ?? "", {
        type: "application/pdf",
      });
      const href = window.URL.createObjectURL(blobFile);
      fileUrl.value = href;
    } catch {
      isFileErrored.value = true;
      isFileLoaded.value = true;
    }
  }
});

const currentMimeType = computed<string | undefined>(() => {
  const idFileType = props.activeTransaction.file?.idFileType ?? -1;
  try {
    return idFileTypeToMimeType(idFileType);
  } catch {
    return undefined;
  }
});
</script>

<style lang="scss" scoped>
@use "@/assets/styles/halfPage.scss";
</style>
