<template>
  <div class="aairp-container">
    <div class="rph-container">
      <div v-if="allTransactionsAreUploaded" class="close-button" @click="$emit('close')">
        <uds-icon icon-name="close" :color="udsColors.udsNeutral800" size="large" />
      </div>
      <div>
        <div class="uds-headers">
          <span v-if="!isExpenseNote"
            >Annotez votre facture {{ isPurchases ? "d'achat" : "de vente" }}</span
          >
          <span v-else>Annotez votre note de frais</span>
          <span style="margin-left: 8px" class="primary-color">
            {{ transactionPosition }}/{{ numberOfTransactions }}
          </span>
        </div>
      </div>
      <div class="action-button">
        <div id="pdfDownloadChip" />
        <uds-tooltip tooltip-text="Envoyer par mail">
          <uds-action-button
            icon-name="mail"
            class="icon"
            size="small"
            @click="openSendPurchaseDocumentModal()"
          />
        </uds-tooltip>
        <div id="sliceChip" />
        <uds-tooltip tooltip-text="Avoir ou remboursement">
          <uds-action-button
            icon-name="autorenew"
            :selected="props.activeTransaction.isAsset"
            class="icon"
            size="small"
            @click="saveIsAsset()"
          />
        </uds-tooltip>
        <uds-tooltip tooltip-text="Supprimer">
          <uds-action-button
            icon-name="delete"
            class="icon"
            size="small"
            @click="
              $emit('deleteTransaction', activeTransaction.id), (isTriggerNewLocalEntry = false)
            "
          />
        </uds-tooltip>
      </div>
    </div>
    <LazyAaiRightPanelAccrualForm
      v-if="isMounted"
      :key="`spform-${activeTransactionIndex}`"
      :transaction="activeTransaction"
      :is-from-purchase="isPurchases"
    />
    <div class="rpf-container">
      <div @click="allTransactionsAreUploaded && $emit('close')">
        <uds-main-button :disabled="!allTransactionsAreUploaded" size="small" type="secondary"
          >Retour a la liste</uds-main-button
        >
      </div>
      <div
        v-if="activeTransactionIndex + 1 > 1"
        @click="$emit('previousTransaction'), (isTriggerNewLocalEntry = false)"
      >
        <uds-main-button size="small" icon-name="west"> Facture précédente </uds-main-button>
      </div>
      <div
        v-if="activeTransactionIndex + 1 < numberOfTransactions"
        @click="$emit('nextTransaction'), (isTriggerNewLocalEntry = false)"
      >
        <uds-main-button size="small" icon-name="arrow_right_alt" icon-right>
          Facture suivante
        </uds-main-button>
      </div>
      <div v-if="activeTransactionIndex + 1 === numberOfTransactions" @click="$emit('close')">
        <uds-main-button size="small">Terminer</uds-main-button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { ReadAccrualInvoice } from "@silexpert/core";
import { useModal } from "vue-final-modal";
import SendPurchasesDocument from "@/components/commons/modals/SendPurchasesDocument.vue";

defineEmits(["deleteTransaction", "previousTransaction", "nextTransaction", "close"]);

const props = defineProps<{
  activeTransaction: ReadAccrualInvoice;
  // activeOriginalTransaction: ReadAccrualInvoice;
  activeTransactionIndex: number;
  transactions: ReadAccrualInvoice[];
  isPurchases: boolean;
  isExpenseNote: boolean;
}>();

const udsColors = getUdsColors();
const annotateStore = useAnnotateStore();

const isMounted = ref(false);

onMounted(() => {
  isMounted.value = true;
});

const allTransactionsAreUploaded = computed<boolean>(
  () => numberOfTransactions.value === annotateStore.totalFileToUpload,
);

const isTriggerNewLocalEntry = ref(false);

const transactionItem = computed(() => useAnnotationComposable(props.activeTransaction));

const numberOfTransactions = computed<number>(() => props.transactions.length);

const transactionPosition = computed<number>(
  () => props.transactions.map((tr) => tr.id).indexOf(props.activeTransaction.id) + 1,
);

function saveIsAsset() {
  transactionItem.value.annotate({
    payload: { isAsset: !props.activeTransaction.isAsset },
    dontWaitForApiResult: true,
  });
}

async function openSendPurchaseDocumentModal(): Promise<void> {
  const file = props.activeTransaction.file;

  if (!isDefined(file)) {
    $notifier().open({ type: "error", content: "Fichier inconnu, envoie de mail impossible." });
    return;
  }

  const { open, close } = useModal({
    component: SendPurchasesDocument,
    attrs: {
      idFile: props.activeTransaction.file!.id!,
      onClose() {
        close();
      },
      onCancel() {
        close();
      },
    },
  });
  await open();
}
</script>

<style lang="scss" scoped>
.aairp-container {
  background-color: $uds-white;

  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.rph-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: $uds-spacing-3;
  width: 100%;
  margin: $uds-spacing-3 0;

  .action-button {
    display: flex;
    align-items: flex-start;
    gap: $uds-spacing-1;
    width: 575px;
  }

  .close-button {
    position: absolute;
    top: 35px;
    right: 35px;

    width: 14px;
    height: 14px;

    display: flex;
    justify-content: center;
    align-items: center;

    cursor: pointer;
  }
}

.rpf-container {
  background-color: $uds-white;
  box-shadow: 0px -5px 4px rgba(0, 0, 0, 0.03);
  padding: $uds-spacing-3;
  width: 100%;
  box-sizing: border-box;

  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: $uds-spacing-3;

  .link-style {
    cursor: pointer;
    font-style: normal;
    font-weight: $uds-weight-450;
    font-size: 14px;
    line-height: 18px;
    color: $uds-primary-500;
  }
}
</style>
